var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "content"
  }, [_vm.loading ? _c('loading-overlay') : _vm._e(), !_vm.loading && _vm.error ? _c('loading-overlay', [_vm._v(" Возникла ошибка. Попробуйте перезагрузить страницу ")]) : _vm._e(), _c('cell-list', {
    attrs: {
      "gap": "24"
    }
  }, [_vm._l(_vm.cells, function (cell, key) {
    return [cell.multi ? _c('cell-item-multi', {
      key: key,
      attrs: {
        "items": _vm.getMulti(key),
        "color": cell.color,
        "title": cell.title
      }
    }) : _c('cell-item', {
      key: key,
      attrs: {
        "title": cell.title,
        "info": _vm.getLoaded(key).total,
        "infoMin": _vm.getSign(key),
        "color": cell.color,
        "chart": _vm.getLoaded(key).dynamic
      }
    })];
  })], 2)], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };