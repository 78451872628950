var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "partner-summary"
  }, [_c('div', {
    staticClass: "rewards-title"
  }, [_vm._v("Текущий уровень вознаграждения")]), _c('div', {
    staticClass: "rewards mt24"
  }, [_c('div', {
    staticClass: "rewards__bar"
  }), _c('div', {
    staticClass: "rewards__filled",
    style: {
      'grid-column': `1 / ${_vm.currentRewardIndex + 2}`
    }
  }), _vm._l(_vm.rewards, function (item, i) {
    return [_c('div', {
      key: `rp:${i}`,
      staticClass: "reward-percent",
      class: {
        filled: _vm.currentRewardIndex >= i
      },
      style: {
        'grid-column': `${i + 1} / ${i + 2}`
      }
    }, [_vm._v(" " + _vm._s(item.percent) + "% ")]), _c('div', {
      key: `rt:${i}`,
      staticClass: "reward-text",
      style: {
        'grid-column': `${i + 1} / ${i + 2}`
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")])];
  })], 2), _c('div', {
    staticClass: "balance mt32"
  }, [_vm._v(" Баланс " + _vm._s(_vm.state.partner.balance.toLocaleString()) + " " + _vm._s(_vm.plural(_vm.state.partner.balance, ["рубль", "рубля", "рублей"])) + " ")]), _c('div', {
    staticClass: "tip mt16"
  })]);
};
var staticRenderFns = [];
export { render, staticRenderFns };