var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    attrs: {
      "table-id": "partner_withdrawals",
      "id": _vm.id,
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "id",
      "selectable": false
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        item
      }) {
        return [_c('span', {
          class: [`status-${item.status}`]
        }, [_vm._v(" " + _vm._s(_vm.statusValues[item.status]) + " ")])];
      }
    }, {
      key: "method",
      fn: function ({
        item
      }) {
        return [_c('span', {
          class: [`method-${item.method}`]
        }, [_vm._v(" " + _vm._s(_vm.methodValues[item.method]) + " ")])];
      }
    }])
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };