import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("partner/getVisits", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      return [{
        title: "Дата",
        show: true,
        name: "date",
        width: 100,
        type: "date"
      }, {
        title: "Переходов",
        show: true,
        name: "visits",
        width: 100,
        filter: "numberRange"
      }, {
        title: "Регистраций",
        show: true,
        name: "registrations",
        width: 100,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};