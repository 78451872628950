import Clipboard from "clipboard";
export default {
  data() {
    return {
      clipboard: null,
      showFeedback: false
    };
  },
  props: {
    component: {
      type: [Object, String],
      required: true
    },
    content: {
      type: String,
      required: true
    },
    feedbackContent: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.clipboard = new Clipboard(this.$el);
    this.clipboard.on("success", () => {
      this.$emit("copied");
      if (this.feedbackContent) {
        this.showFeedback = true;
        setTimeout(() => this.showFeedback = false, 1000);
      }
    });
  },
  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  }
};