var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "partner-withdrawal"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Выберите метод")]), _c('div', {
    staticClass: "mt24"
  }, [_c('UiFormVariantsInput', {
    attrs: {
      "items": _vm.methods
    },
    model: {
      value: _vm.method,
      callback: function ($$v) {
        _vm.method = $$v;
      },
      expression: "method"
    }
  })], 1), _c('div', {
    staticClass: "mt32 button-row"
  }, [_c('UiButton', {
    attrs: {
      "variant": "blue",
      "disabled": _vm.state.partner.balance < 3000 || !_vm.method
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Оформить ")]), _vm.state.partner.balance < 3000 ? _c('div', {
    staticClass: "min-notice"
  }, [_vm._v(" Минимальная сумма для вывода - 3000 р, вывод активируется при достижении этой суммы ")]) : _vm._e()], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };