var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.ready ? _c('div', [_c('div', {
    staticClass: "grid"
  }, [_c('div', {
    staticClass: "card invite"
  }, [_c('div', {
    staticClass: "card__title"
  }, [_vm._v("Поделиться партнерской ссылкой")]), _vm.partnerLink ? [_c('div', {
    staticClass: "mt16"
  }, [_c('a', {
    staticClass: "partner-link",
    attrs: {
      "href": _vm.partnerLink,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.partnerLink))])]), _c('div', {
    staticClass: "mt16"
  }, [_c('UiClipboard', {
    attrs: {
      "content": _vm.partnerLink,
      "component": _vm.components.UiButton,
      "feedback-content": "Скопировано"
    }
  }, [_vm._v(" Скопировать ")])], 1)] : _vm._e(), _c('div', {
    staticClass: "mt16"
  }, [_vm._v(" Поделитесь данной ссылкой с коллегами и друзьями, если после перехода они зарегистрируются в сервисе - вы будете получать вознаграждение с каждой их оплаты! ")]), _c('div', {
    staticClass: "card__title mt16"
  }, [_vm._v("Где можно разместить ссылку?")]), _vm._m(0), _c('div', {
    staticClass: "card__title mt16 red-text"
  }, [_vm._v(" Какие каналы запрещены? ")]), _vm._m(1)], 2), _c('div', {
    staticClass: "card stats"
  }, [_c('PartnerSummary', {
    attrs: {
      "state": _vm.state
    }
  })], 1), _c('div', {
    staticClass: "card withdrawal"
  }, [_c('div', {
    staticClass: "card__title"
  }, [_vm._v("Оформить вывод средств")]), _c('div', {
    staticClass: "mt32"
  }, [_c('PartnerWithdrawal', {
    attrs: {
      "state": _vm.state
    },
    on: {
      "complete": _vm.onWithdrawalCreated
    }
  })], 1)])]), _c('UiMainTitle', {
    staticClass: "mt64"
  }, [_vm._v("Статистика ")]), _c('div', {
    staticClass: "mt16"
  }, [_c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables,
      "route-param": "tab"
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings && _vm.tabs_tables_model ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: `${_vm.tabs_tables_model.id}:${_vm.refreshTables}`,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "file-name": _vm.fileName
    }
  }) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mt16"
  }, [_c('ul', {
    staticClass: "partner-page-ul"
  }, [_c('li', [_vm._v("Личный блог или канал")]), _c('li', [_vm._v("Тематические ресурсы и форумы")]), _c('li', [_vm._v("Рассылка по своей базе (кроме СПАМа)")]), _c('li', [_vm._v("Контекстная реклама (кроме бренд-запросов)")]), _c('li', [_vm._v("Курсы и тренинги")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "mt16"
  }, [_c('ul', {
    staticClass: "partner-page-ul"
  }, [_c('li', [_vm._v("Купонные сайты")]), _c('li', [_vm._v(" Регистрация по своей ссылке (партнер и клиент - одно лицо) ")]), _c('li', [_vm._v("Контекстная реклама на бренд SalesFinder")]), _c('li', [_vm._v("Разделение скидки с рефералами")])])]);
}];
export { render, staticRenderFns };