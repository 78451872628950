var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', _vm._g({
    staticClass: "cell",
    class: _vm.color
  }, _vm.$listeners), [_c('div', {
    staticClass: "cell__title",
    style: {
      color: _vm.colors[_vm.color].color
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "spacing"
  }), _c('div', {
    staticClass: "cell__chart"
  }, [_vm.chartData ? _c('line-chart', {
    key: _vm.refresh,
    attrs: {
      "chartData": _vm.chartData,
      "options": _vm.chartOptions,
      "height": 96
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "spacing"
  }), _c('div', {
    staticClass: "cell__info"
  }, [_vm._v(" " + _vm._s(_vm.format(_vm.info)) + " "), _vm.infoMin ? _c('span', {
    staticClass: "cell__info-min"
  }, [_vm._v(" " + _vm._s(_vm.infoMin) + " ")]) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };