var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(_vm.component, {
    tag: "component",
    attrs: {
      "data-clipboard-text": _vm.content
    }
  }, [_vm.showFeedback ? [_vm._v(_vm._s(_vm.feedbackContent))] : _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };