import "core-js/modules/esnext.iterator.map.js";
import CellItem from "@/components/Cell/ItemAlt.vue";
import CellItemMulti from "@/components/Cell/ItemMulti.vue";
import CellList from "@/components/Cell/List.vue";
import LoadingOverlay from "@/components/Loading/Overlay";
export default {
  components: {
    CellItem,
    CellItemMulti,
    CellList,
    LoadingOverlay
  },
  props: {
    loaderConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cells: {
        visits: {
          id: "visits",
          title: "Переходы",
          color: "cyan"
        },
        registrations: {
          id: "registrations",
          title: "Регистрации",
          color: "red"
        },
        payments: {
          id: "payments",
          title: "Платежи",
          color: "blue"
        },
        incomes: {
          id: "incomes",
          title: "Начисления",
          color: "violet"
        }
      },
      loaded: null,
      loading: false,
      error: false
    };
  },
  methods: {
    loader() {
      return this.$store.dispatch("partner/overviewAll", this.loaderConfig);
    },
    getLoaded(type) {
      if (!this.loaded || !this.loaded[type]) {
        return {
          total: null,
          dynamic: []
        };
      }
      return this.loaded[type];
    },
    getSign(key) {
      const cell = this.cells[key];
      if (cell.percentOf) {
        const v = this.getLoaded(key).total || 0;
        const base = this.getLoaded(cell.percentOf).total;
        const percent = base ? Number(v / base * 100).toFixed(0) : null;
        return percent ? `(${percent}%)` : null;
      }
      return cell.unit;
    },
    getMulti(type) {
      return this.cells[type].multi.map(cell => {
        return {
          title: cell.title,
          info: this.getLoaded(cell.id).total,
          infoMin: cell.unit
        };
      });
    }
  },
  watch: {
    loaderConfig: {
      immediate: true,
      async handler(v, old) {
        if (JSON.stringify(v) === JSON.stringify(old)) {
          return;
        }
        this.loading = true;
        this.error = false;
        try {
          this.loaded = await this.loader();
        } catch (e) {
          this.loading = false;
          this.error = true;
          throw e;
        }
        this.loading = false;
      }
    }
  }
};