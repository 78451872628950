import "core-js/modules/esnext.iterator.map.js";
import LineChart from "@/components/charts/LineChart.vue";
import colors from "@/utils/colors";
import numbers from "@/utils/numbers";
export default {
  props: ["title", "info", "infoMin", "color", "chart"],
  data() {
    return {
      colors: colors.alt,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
          yAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              tickMarkLength: 0,
              display: true,
              drawBorder: false,
              borderDash: [4, 4],
              borderWidth: 1,
              color: "#D3D8DD80",
              zeroLineBorderDash: [4, 4],
              zeroLineColor: "#D3D8DD80"
            }
            /*
            // afterSetDimensions: function(axes) {
            // //console.log(axes);
            // axes.maxWidth = 13;
            // //console.log(axes.width);
            // }
            */
          }],
          xAxes: [{
            ticks: {
              display: false
            },
            gridLines: {
              display: false
            }
          }]
        },
        tooltips: {
          enabled: false
        },
        layout: {
          padding: {
            top: 8,
            left: 16,
            right: 16,
            bottom: 8
          }
        }
      },
      refresh: 0
    };
  },
  components: {
    LineChart
  },
  mounted() {},
  computed: {
    chartData() {
      if (!this.chart || !this.chart.length) {
        return null;
      }
      const chart = this.chart;
      return {
        labels: chart.map((data, i) => i + 1),
        datasets: [{
          borderColor: this.colors[this.color].color,
          borderWidth: 1,
          backgroundColor: "reversedGradient",
          pointBorderColor: "transparent",
          pointRadius: 0,
          data: chart
        }]
      };
    }
  },
  watch: {
    chart() {
      this.refresh++;
    }
  },
  methods: {
    format(number) {
      if (typeof number !== "number") {
        return number;
      }
      return numbers.format(number, "ru", 1000000);
    }
  }
};