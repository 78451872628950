import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { plural } from "@/utils/numbers";
export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      invited: 11,
      rewards: [
      /* {
          percent: 10,
          requirements: 0,
          text: "1-5",
      },
      {
          percent: 15,
          requirements: 6,
          text: "6-10",
      }, */
      {
        percent: 20,
        requirements: 11
        // text: "11-20",
      } /*
        {
          percent: 30,
          requirements: 20,
          text: "20+",
        }, */]
    };
  },
  computed: {
    currentRewardIndex() {
      const reward = [...this.rewards].reverse().find(reward => this.invited >= reward.requirements);
      return this.rewards.indexOf(reward);
    }
  },
  methods: {
    plural() {
      return plural(...arguments);
    }
  }
};